@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    overflow-x: hidden;
}

.link {
    text-decoration: none;
    color: inherit;
    display: block;
}

ul {
    list-style: none;
}

input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.sideLogo {
    border-radius: 50%;
    padding: 5px;
    border: 1px solid rgb(161, 102, 102);
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.link:hover {
    color: inherit;
}

.hoverPrimary:hover {
    color: #167aff;
}

#hallloid {
    max-width: 50px !important;
    min-width: 50px !important;
    width: 50px !important;
}

.w-33 {
    width: 33% !important;
}

.linkHover:hover {
    background: transparent !important;
}

.linkActive {
    color: black !important;
}

.hedIcon:hover {
    background-color: #f5f4f4 !important;
    cursor: pointer;
    transition: all .35s ease;
}

.appCard:hover {
    background-color: #fbfbfb !important;
}

.ant-layout-sider-children {
    background-color: #fbfbfb !important;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    background-color: #fbfbfb !important;
}

.appCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
    width: 91px;
    border-radius: 9px;
    height: 80px;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-submenu-hidden {
    /* display: block  !important; */
    position: absolute !important;
    top: 1rem !important;
    height: 94vh !important;
}

.accounDrop::-webkit-scrollbar {
    width: 6px;
    background-color: #f0f0f0;
}

.accounDrop::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 3px;
}

.topSearchBar {
    display: flex;
    align-items: center;
    height: 38px;
    width: 220px;
    border: 1px solid lightgray;
    border-radius: 30px;
}

.topSearchBar input {
    height: 25px;
    border: none;
    width: 184px;
}

.topSearchBar input:focus {
    border: none;
    outline: none;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light>.ant-menu .ant-menu-item-selected {
    background-color: transparent !important;
}

.auth-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;
    background: #4680ff;
}

.usericonHover:hover {
    background-color: transparent !important;
}

@media (min-width: 1200px) {
    .auth-wrapper .container {
        max-width: 1140px;
    }
}

@media (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .auth-wrapper {
        display: block;
        margin: 0 auto;
    }

    .auth-wrapper>.row {
        min-height: 100vh;
    }

    .auth-wrapper>.row>.aut-bg-img {
        min-height: 100vh;
    }
}

.auth-wrapper .saprator {
    position: relative;
    margin: 8px 0;
}

.auth-wrapper .saprator span {
    background: #fff;
    position: relative;
    padding: 0 10px;
    z-index: 5;
    font-size: 20px;
}

.auth-wrapper .saprator:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e2e5e8;
    z-index: 1;
}

.auth-wrapper a,
.auth-wrapper p>a {
    color: #37474f;
    font-weight: 600;
}

.auth-wrapper .input-group {
    background: transparent;
}

.auth-wrapper .card {
    margin-bottom: 0;
    padding: 8px;
}

.auth-wrapper .card .card-body {
    padding: 20px 25px 20px 40px;
}

@media (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .auth-wrapper .card {
        display: block;
    }
}

.auth-wrapper>div {
    z-index: 5;
}

.auth-wrapper .auth-content {
    position: relative;
    padding: 15px;
    z-index: 5;
}

.auth-wrapper .auth-content:not(.container) {
    width: 400px;
}

.auth-wrapper .auth-content:not(.container) .card-body {
    padding: 40px 35px;
}

@media (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .auth-wrapper .auth-content {
        margin: 0 auto;
    }
}

.auth-wrapper .auth-side-img {
    padding-right: 400px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
}

@media only screen and (max-width: 991px) {
    .auth-wrapper .auth-side-img {
        display: none;
    }
}

.auth-wrapper .auth-side-form {
    position: relative;
    min-height: 100%;
    background: #fff;
    display: inline-flex;
    align-items: center;
    left: auto;
    float: right;
}

.auth-wrapper .auth-side-form>* {
    position: relative;
    z-index: 5;
}

@media only screen and (max-width: 991px) {
    .auth-wrapper .auth-side-form {
        width: 100%;
        justify-content: center;
    }

    .auth-wrapper .auth-side-form .auth-content:not(.container) {
        max-width: 350px;
    }
}

@media (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .auth-wrapper.aut-bg-img .auth-side-form {
        height: 100vh;
    }
}

@media only screen and (max-width: 767px) {
    .auth-wrapper .card .card-body {
        padding: 30px 15px;
    }
}

.auth-wrapper.offline {
    background-image: none;
}

.auth-wrapper.offline h1 {
    font-size: 87px;
    font-weight: 700;
}

.auth-wrapper.offline:before {
    display: none;
}

.auth-wrapper .card-body .carousel-indicators {
    margin: 15px 0 10px;
    bottom: 0;
}

.auth-wrapper .card-body .carousel-indicators li {
    width: 50px;
    background-color: rgba(70, 128, 255, 0.4);
    border-radius: 5px;
    height: 4px;
}

.auth-wrapper .card-body .carousel-indicators li.active {
    background-color: #4680ff;
}

.auth-wrapper .img-logo-overlay {
    position: absolute;
    top: 40px;
    left: 50px;
}

.btn-primary:hover {
    color: #fff;
    background-color: #2066ff;
}

.has-ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

/* search bar modal */
.demo-logo-vertical {
    height: 64px;
    background: #334454;
    display: flex;
    justify-content: center;
    align-items: center;
}

.demo-logo-vertical svg {
    cursor: pointer;
}


.CompanySettingDropdown .ant-menu-submenu-arrow {
    padding-left: 1rem;
}

.indexContainer {
    position: relative;
}

.searchTopModal {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: all .35s linear;
}

.indexContainer .headerContent {
    display: flex;
    align-items: center;
}

.indexContainer .headerUserBell {
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.indexContainer .card .card_header {
    background-color: black;
    color: white;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentOverflow {
    overflow-y: scroll;
    height: 80vh;
}

.contentOverflow::-webkit-scrollbar {
    display: none;
}


/* YourCSSFile.css */

.collapsible {
    background-color: transparent;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;

}

.content {
    overflow: hidden;
    transition: all .5s ease;
}

/* Increase the font size of the header text */
.brFloq {
    font-size: 16px;
    font-weight: 600;
}

.custom-cell {
    font-size: 15px;
}

.user-card .cover-img-block {
    position: relative;
}

.user-card .cover-img-block .change-cover {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 5;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.user-card .cover-img-block .change-cover .dropdown-toggle {
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-card .cover-img-block .change-cover .dropdown-toggle:after {
    display: none;
}

/* .user-card .cover-img-block .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in-out;
}

.user-card .cover-img-block:hover .change-cover,
.user-card .cover-img-block:hover .overlay {
    opacity: 1;
}

.user-card .user-about-block {
    margin-top: -30px;
}

.user-card .user-about-block img {
    box-shadow: 0 0 0 5px #fff;
} */

/* .user-card .user-about-block .certificated-badge {
    position: absolute;
    bottom: -5px;
    right: -5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #fff;
    padding: 5px 3px;
} */
/* 
.user-card .user-about-block .certificated-badge .bg-icon {
    font-size: 22px;
}

.user-card .user-about-block .certificated-badge .front-icon {
    font-size: 11px;
    position: absolute;
    top: 11px;
    left: 9px;
} */

/* .user-card .hover-data {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(36, 46, 62, 0.92);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
} */

/* .user-card:hover .hover-data {
    transform: scale(1);
}

.user-card .change-profile .dropdown-toggle::after {
    display: none;
}

.user-card .change-profile .profile-dp {
    position: relative;
    overflow: hidden;
    padding: 5px;
    width: 110px;
    height: 110px;
    border-radius: 5px;
    border: 1px solid;

} */

/* .user-card .change-profile .profile-dp .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    opacity: 0;
    z-index: 1;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in-out;
} */

.user-card .change-profile .profile-dp .overlay span {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0 0 5px;
}

.user-card .change-profile .profile-dp:hover .overlay {
    opacity: 1;
}

@media only screen and (max-width: 992px) {
    .user-card .user-about-block {
        margin-top: -10px;
    }

    .user-card .wid-80 {
        width: 60px;
    }

    .user-card .change-profile .profile-dp {
        width: 90px;
        height: 90px;
    }

    .user-card.user-card-2.shape-right .cover-img-block {
        -webkit-clip-path: none;
        clip-path: none;
    }

    .user-card .cover-img-block .change-cover .dropdown-toggle {
        padding: 5px 7px;
    }
}

.user-card-2 .cover-img-block {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 80%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 80%);
}

.user-card-2.shape-right .cover-img-block {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
}

.user-card-2.shape-center .cover-img-block {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0% 80%);
    clip-path: polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0% 80%);
}

.user-card-3 .certificated-badge {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #fff;
    padding: 5px 3px;
}

.user-card-3 .certificated-badge .bg-icon {
    font-size: 30px;
}

.user-card-3 .certificated-badge .front-icon {
    font-size: 14px;
    position: absolute;
    top: 14px;
    left: 13px;
}

.user-card-3 .social-top-link {
    position: absolute;
    top: 20px;
    left: 20px;
}

.user-card-3.social-hover .social-top-link {
    top: 20px;
    left: 20px;
    overflow: hidden;
}

.user-card-3.social-hover .social-top-link .btn,
.user-card-3.social-hover .social-top-link li {
    margin-left: -100px;
    transition: all 0.2s ease-in-out;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(1),
.user-card-3.social-hover .social-top-link li:nth-child(1) {
    transition-delay: 0.05s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(2),
.user-card-3.social-hover .social-top-link li:nth-child(2) {
    transition-delay: 0.1s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(3),
.user-card-3.social-hover .social-top-link li:nth-child(3) {
    transition-delay: 0.15s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(4),
.user-card-3.social-hover .social-top-link li:nth-child(4) {
    transition-delay: 0.2s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(5),
.user-card-3.social-hover .social-top-link li:nth-child(5) {
    transition-delay: 0.25s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(6),
.user-card-3.social-hover .social-top-link li:nth-child(6) {
    transition-delay: 0.3s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(7),
.user-card-3.social-hover .social-top-link li:nth-child(7) {
    transition-delay: 0.35s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(8),
.user-card-3.social-hover .social-top-link li:nth-child(8) {
    transition-delay: 0.4s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(9),
.user-card-3.social-hover .social-top-link li:nth-child(9) {
    transition-delay: 0.45s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(10),
.user-card-3.social-hover .social-top-link li:nth-child(10) {
    transition-delay: 0.5s;
}

.user-card-3.social-hover:hover .social-top-link .btn,
.user-card-3.social-hover:hover .social-top-link li {
    margin-left: 0;
}

.user-profile {
    margin-top: -25px;
    margin-left: -25px;
    margin-right: -25px;
    background: #fff;
}

.user-profile .card-body {
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 4px -2px rgba(54, 80, 138, 0.3);
}

.user-profile .card-body .overlay>span {
    cursor: pointer;
}

.user-profile .cover-img-block:hover>img {
    -webkit-filter: grayscale(1) blur(1px);
    filter: grayscale(1) blur(1px);
}

.user-profile .profile-dp .overlay {
    text-align: left;
}

.user-profile .user-about-block .certificated-badge {
    bottom: 8px;
    right: 0;
    z-index: 2;
}

.user-profile .profile-tabs {
    border-bottom: 0;
    margin-top: 30px;
}

.user-profile .profile-tabs .nav-item {
    margin-bottom: -0px;
}

.user-profile .profile-tabs .nav-item .nav-link {
    position: relative;
}

.user-profile .profile-tabs .nav-item .nav-link:after {
    content: "";
    background: #4680ff;
    position: absolute;
    transition: all 0.3s ease-in-out;
    left: 50%;
    right: 50%;
    bottom: -1px;
    height: 2px;
    border-radius: 2px 2px 0 0;
}

.user-profile .profile-tabs .nav-link:hover {
    border-color: transparent;
}

.user-profile .profile-tabs .nav-item.show .nav-link,
.user-profile .profile-tabs .nav-link.active {
    border-color: transparent;
}

.user-profile .profile-tabs .nav-item.show .nav-link:after,
.user-profile .profile-tabs .nav-link.active:after {
    left: 0;
    right: 0;
}

.pcoded-content {
    position: relative;
    display: block;
    padding: 25px;
}

.pcoded-main-container {
    position: relative;
    margin: 0 auto;
    padding-top: 0;
    display: block;
    clear: both;
    float: unset;
    right: 0;
    top: 0;
    /* margin-left: 230px; */
    min-height: 100vh;
    transition: all 0.3s ease-in-out;
}

.employeeProfileData .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.employeeProfileData .tabLabel {
    /* font-weight: 600; */
    font-size: 17px;
}

.tableCell {
    display: flex;
    align-items: center;
}

.tableCell .text-dark {
    font-weight: 600;
    color: #535353 !important;
}

.bg-danger-g:hover {
    cursor: pointer;
    background-color: #001529;
    transition: .35s all ease;
}

.tableCellProfile .linkk {
    color: #535353 !important;
    font-weight: 600;
}

.tableCellProfile .linkk p {
    font-weight: 400;
}

.bg-danger-g:hover .tableCell .text-dark {
    color: white !important;
}

.bg-danger-g:hover .ant-progress-text {
    color: white !important;
}

.bg-danger-g:hover .ant-progress-inner {
    background-color: white !important;
}

.bg-danger-g:hover .tableCellProfile .linkk {
    color: #fff !important;
    font-weight: 600;
}

.custom-select .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
}

.dateInput {
    box-shadow: none !important;
}

.dateInput:focus {
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid red !important;
}

.namecolumn {
    text-align: center !important;
}

.pagination-container {
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.pagination-container .btn-outline-mute:focus {
    box-shadow: none !important;
}

.image-container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image-container img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btnn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.employeeProfileData .user-profile {
    border-radius: 10px;
}

.employeeProfileData .pcoded-main-container {
    background: transparent;
    position: relative;
    margin: 0 auto;
    padding-top: 0;
    display: block;
    clear: both;
    float: unset;
    right: 0;
    top: 0;
    min-height: 100vh;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}

.employeeProfileData .headrow {
    height: 50px;
    border-bottom: 1px solid lightgray;
    display: flex !important;
    align-items: center !important;
    margin-bottom: 10px;
}

.employeeProfileData .employeeBody .heh1 {
    font-size: 17px;
    padding-top: 11px;
    /* font-weight: 600; */
}

.employeeProfileData .chart-container canvas {
    height: 224px !important;
    /* width: 330px !important; */
}

.employeeProfileData .chart-container,
.employeeProfileData .dtcol {
    border: 1px solid lightgray;
    padding: 1rem;
    border-radius: 10px;
    /* margin-bottom: 1rem; */
    height: 100%;
}

.employeeProfileData .dtcoll {
    border: 1px solid lightgray;
    padding: 1rem;
    border-radius: 10px;
    /* margin-bottom: 1rem; */
    height: 100%;
    width: 100%;
}

.employeeProfileData .dtcoll .change-profile {
    display: flex;
    justify-content: center;
}

.employeeProfileData .user-card .change-profile .profile-dp {
    height: 160px;
    width: 160px;
}

.projectData .tableCell {
    height: 60px;
}

.projectData .ant-progress-status-active {
    width: 14rem;
    min-width: 100%;
}

.designationData .tableCell {
    height: 60px;
}

.designationData .MuiFormControl-root {
    width: 100% !important;
}

.designationData .ant-select-selector-root {
    border: 0 !important;
}

.designationData .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    margin-top: 29px !important;
}

.employeeData .breadcrumb .breadcrumb-item {
    font-weight: 500;
    font-size: 14px;
}

.projectDetailData .overviewData .progressCard .cardHeader {
    background-color: #6690F4;
}

.projectDetailData .overviewData .progressCard {
    border-radius: 10px;
}

.projectDetailData .overviewData .progressCard .validity {
    padding: 6px;
}

/* .projectDetailData .overviewData .progressCard .validity p{
    border-top: 1px solid black;
} */

.projectDetailData .overviewData .progressCircle {
    border-radius: 10px 10px 0 0;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    z-index: 1;
}

.projectDetailData .overviewData .progressCircle .ant-space {
    padding: .4rem;
    border-radius: 50%;
    background: #fff;
}

.projectDetailData .overviewData .progressCircle .ant-progress-inner {
    height: 100px !important;
    width: 100px !important;
}

.projectDetailData .overviewData .progressCircle::after {
    position: absolute;
    content: '';
    border-radius: 10px 10px 0 0;
    z-index: -1;
    width: 100%;
    top: 0;
    left: 0;
    height: 70%;
    background-color: #6690F4;
}

.social-link {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    border-radius: 50%;
    transition: all 0.3s;
    font-size: 0.9rem;
}

.social-link:hover,
.social-link:focus {
    background: #ddd;
    text-decoration: none;
    color: #555;
}

.react-kanban-card .react-kanban-card__title {
    background: #000 !important;
    color: #fff !important;
}

.react-kanban-column {
    border-radius: 0px 5px 5px 0px !important;
    background-color: #ffffff47 !important;
    box-shadow: 0px 0px 10px #8d8d8d36 !important;
}

.react-kanban-column:nth-child(1) .react-kanban-card {
    background-color: #ecececa6 !important;
    border-radius: 0px 5px 5px 0px !important;
    border-left: 3px solid red !important;
    overflow-x: scroll;
}

.react-kanban-column:nth-child(1) .react-kanban-card::-webkit-scrollbar {
    width: 6px;
}

.react-kanban-column:nth-child(1) .react-kanban-card::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.react-kanban-column:nth-child(1) .react-kanban-card::-webkit-scrollbar-thumb {
    display: none;
}

.react-kanban-column:nth-child(1) .react-kanban-card::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.react-kanban-column:nth-child(1) .react-kanban-card__description {
    padding: 0 !important;
}

.react-kanban-column:nth-child(1) .react-kanban-column-header {
    height: 40px !important;
    border: 1px solid !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin-bottom: 1rem !important;
    background-color: red !important;
    color: white !important;
    border-radius: 5px !important;

}

.react-kanban-column:nth-child(1) .react-kanban-card .react-kanban-card__title {
    background: red !important;
    color: #fff !important;
    width: max-content !important;
    border-radius: 0px 5px 5px 0px !important;
    padding: .5rem !important;
}

.react-kanban-column:nth-child(2) .react-kanban-card {
    background-color: #ecececa6 !important;
    border-radius: 0px 5px 5px 0px !important;
    border-left: 3px solid orange !important;
    overflow-x: scroll;
}

.react-kanban-column:nth-child(2) .react-kanban-card::-webkit-scrollbar {
    width: 6px;
}

.react-kanban-column:nth-child(2) .react-kanban-card::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.react-kanban-column:nth-child(2) .react-kanban-card::-webkit-scrollbar-thumb {
    display: none;
}

.react-kanban-column:nth-child(2) .react-kanban-card::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.react-kanban-column:nth-child(2) .react-kanban-card__description {
    padding: 0 !important;
}


.react-kanban-column:nth-child(2) .react-kanban-card__description {
    padding: 0 !important;
}

.react-kanban-column:nth-child(2) .react-kanban-column-header {
    height: 40px !important;
    border: 1px solid !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin-bottom: 1rem !important;
    background-color: orange !important;
    color: white !important;
    border-radius: 5px !important;
}

.react-kanban-column:nth-child(2) .react-kanban-card .react-kanban-card__title {
    background: orange !important;
    color: #fff !important;
    width: max-content !important;
    border-radius: 3px !important;
    padding: .5rem !important;
}

.react-kanban-column:nth-child(3) .react-kanban-card {
    background-color: #ecececa6 !important;
    border-radius: 0px 5px 5px 0px !important;
    border-left: 3px solid black !important;
    overflow-x: scroll;
}

.react-kanban-column:nth-child(3) .react-kanban-card::-webkit-scrollbar {
    width: 6px;
}

.react-kanban-column:nth-child(3) .react-kanban-card::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.react-kanban-column:nth-child(3) .react-kanban-card::-webkit-scrollbar-thumb {
    display: none;
}

.react-kanban-column:nth-child(3) .react-kanban-card::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.react-kanban-column:nth-child(3) .react-kanban-card__description {
    padding: 0 !important;
}


.react-kanban-column:nth-child(3) .react-kanban-card__description {
    padding: 0 !important;
}

.react-kanban-column:nth-child(3) .react-kanban-column-header {
    height: 40px !important;
    border: 1px solid !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin-bottom: 1rem !important;
    background-color: #000 !important;
    color: white !important;
    border-radius: 5px !important;
}

.react-kanban-column:nth-child(3) .react-kanban-card .react-kanban-card__title {
    background: black !important;
    color: #fff !important;
    width: max-content !important;
    border-radius: 3px !important;
    padding: .5rem !important;
}

.react-kanban-column:nth-child(4) .react-kanban-card {
    background-color: #ecececa6 !important;
    border-radius: 0px 5px 5px 0px !important;
    border-left: 3px solid green !important;
    overflow-x: scroll;
}

.react-kanban-column:nth-child(4) .react-kanban-card::-webkit-scrollbar {
    width: 2px !important;
}

.react-kanban-column:nth-child(4) .react-kanban-card::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.react-kanban-column:nth-child(4) .react-kanban-card::-webkit-scrollbar-thumb {
    display: none;
}

.react-kanban-column:nth-child(4) .react-kanban-card::-webkit-scrollbar-thumb:hover {
    background: #8f8e8e;
}

.react-kanban-column:nth-child(4) .react-kanban-card__description {
    padding: 0 !important;
}


.react-kanban-column:nth-child(4) .react-kanban-card__description {
    padding: 0 !important;
}

.react-kanban-column:nth-child(4) .react-kanban-column-header {
    height: 40px !important;
    border: 1px solid !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin-bottom: 1rem !important;
    background-color: green !important;
    color: white !important;
    border-radius: 5px !important;
}

.react-kanban-column:nth-child(4) .react-kanban-card .react-kanban-card__title {
    background: green !important;
    color: #fff !important;
    width: max-content !important;
    border-radius: 3px !important;
    padding: .5rem !important;
}

.ant-select {
    width: 100% !important;
}

/* WizardForm.css */
.wizard-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wizard-form .btn_box {
    display: flex;
    justify-content: flex-end;
}

.wizard-form .btn_box button {
    border: none;
    padding: .5rem 2rem;
    color: white;
    font-weight: bold;
    margin-top: 1rem;
    border-radius: 5px;
    margin-left: 1rem;

}

.wizard-form .btn_box .pre_btn {
    background: #f1f0f0;
    color: #000;
}

.wizard-form .btn_box .next_btn {
    background: #007bff;

}

.wizard-form .btn_box .submit_btn {
    background: #007bff;

}

.progress-indicator {
    display: flex;
    margin-bottom: 20px;
}

.step {
    margin-left: 3rem;
    text-align: center;
}

.step.active {
    color: #007bff;
}

.step-content {
    width: 100%;
}

.step-content input {
    width: 90%;
    /* padding: .3rem; */
    border: none;
    font-size: 13px;


}

.step-content .row {
    border: 1px solid lightgray;
    margin-left: 1rem;
    margin-top: 1rem;
    padding: .5rem;
    border-radius: 5px;
    font-size: 17px;
    color: gray;

}

.form-container {
    width: 100%;
    padding: 20px;
}

.edit_company .nav .nav-item .nav-link {
    border: 1px solid lightgray;
    border-radius: 5px;
    background: #e0e0e02b;
    margin: 0 1rem;
}

.edit_company .nav .nav-item .active {
    border: 1px solid;
    border-radius: 5px;
    background: #007bff;
    color: white;
}

.expense-header {
    position: relative;
}

.expense-header::before {
    content: "";
    width: 100%;
    position: absolute;
    height: 1px;
    background-color: black;
    bottom: -17px;

}

.expenseCategory .table {
    background-color: #fff;
    border-radius: 5px;
    width: 50%;
}

.expenseSubCategory .table {
    background-color: #fff;
    border-radius: 5px;
    width: 70%;
}

.services .table,
.accountExpenses .table {
    background-color: #fff;
    border-radius: 5px;
}

.services .table span,
.accountExpenses .table span {
    background-color: lightcoral;
    padding: 4px;
    border-radius: 2px;
}

.accountExpenses .table th,
.accountExpenses .table td {
    padding: 15px;
    font-size: 13px;
}

.expenseCategory .table th,
.expenseCategory .table td {
    padding: 15px;
    font-size: 13px;
}

.expenseSubCategory .table th,
.expenseSubCategory .table td {
    padding: 15px;
    font-size: 13px;
}

.services .table th,
.services .table td {
    padding: 15px;
    font-size: 13px;
}

#modal-modal-description select {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    border: none;
}

#modal-modal-description input {
    width: 74%;
    font-size: 14px;
    padding: 6px;
    border: none;
}

#modal-modal-description textarea {
    width: 100%;
    height: 120px;
    padding: 5px;
    font-size: 14px;
    border: none;
}

#modal-modal-description .inputFields {
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 20px;
}

.textarea {
    display: flex;
    align-items: baseline;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 4px;
}

.selectBox,
.inputBox {
    border: 1px solid lightgray;
    display: flex;
    align-items: baseline;
    border-radius: 10px;
    padding-left: 5px;
    height: 40px;
}

.selectBox option {
    font-size: 14px;
    margin-top: 5px;
}

.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: rgb(255 11 11 / 8%);
    color: var(--bs-table-hover-color);
}

/* =========================chat =======================*/
.chat .chat-wrapper {
    padding: 0;
    margin: 0;
}

.chat .chat-wrapper .chat-list-wrapper {
    border: 1px solid #ddd;
    height: 70vh;
    overflow-y: auto;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list {
    padding: 0;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li {
    display: block;
    padding: 10px;
    clear: both;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    height: 70px;

}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .avatar {
    margin-right: 12px;
    float: left;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .avatar img {
    width: 40px;
    height: auto;
    border: 4px solid transparent;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .avatar.available img {
    border-color: #2ecc71;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .avatar.busy img {
    border-color: #ff530d;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .body .header {
    margin-top: 4px;
    margin-bottom: 4px;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .body .header .username {
    font-weight: bold;
    font-size: 12px;

}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .body .header .timestamp {
    float: right;
    color: #999;
    font-size: 11px;
    line-height: 18px;
    font-style: italic;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .body .header .timestamp i {
    margin-right: 4px;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li .body p {
    font-size: 11px;
    line-height: 15px;
    max-height: 50px;
    overflow: hidden;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li:hover {
    background-color: #f4f4f4;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li.active {
    background-color: #eee;
    color: black;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li.active .body .timestamp {
    color: black;
}

.chat .chat-wrapper .chat-list-wrapper .chat-list li.new {
    border-left: 2px solid #2ecc71;
}

.chat .chat-wrapper .message-list-wrapper {
    border: 1px solid #ddd;
    height: 452px;
    position: relative;
    overflow-y: auto;
}

.chat .chat-wrapper .message-list-wrapper .message-list {
    padding: 0;
}

.chat .chat-wrapper .message-list-wrapper .message-list li {
    display: block;
    padding: 20px 10px;
    clear: both;
    position: relative;
    color: white;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .avatar {
    margin-right: 12px;
    display: block;
    float: left;
    border-radius: 50%;
    overflow: hidden;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .avatar img {
    width: 60px;
    height: auto;
    border: 2px solid transparent;
    border-radius: 50%;
    overflow: hidden;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .avatar.available img {
    border-color: #2ecc71;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .avatar.busy img {
    border-color: #ff530d;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .username {
    float: left;
    display: none;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .timestamp {
    text-align: left;
    display: block;
    color: #999;
    font-size: 11px;
    line-height: 18px;
    font-style: italic;
    margin-bottom: 4px;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .timestamp i {
    margin-right: 4px;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .body {
    display: block;
    /* width: 87%; */
    border-radius: 10px;
    background: #2ECC71;
    padding: 1rem;
    float: left;
    position: relative;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .body .message {
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    width: auto;
    background: #2ecc71;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .body .message:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent #2ecc71 transparent transparent;
    left: 0;
    top: 10px;
    margin-left: -8px;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .body .message a.white {
    color: white;
    font-weight: bolder;
    text-decoration: underline;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.left .body .message img {
    max-width: 320px;
    max-height: 320px;
    width: 100%;
    height: auto;
    margin-bottom: 5px;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .avatar {
    margin-left: 12px;
    display: block;
    float: right;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #3498DB;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .avatar img {
    width: 60px;
    height: auto;
    border: 2px solid transparent;
    border-radius: 50%;
    overflow: hidden;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .avatar.available img {
    border-color: #2ecc71;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .avatar.busy img {
    border-color: #ff530d;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .username {
    float: right;
    display: none;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .timestamp {
    text-align: right;
    display: block;
    color: #999;
    font-size: 11px;
    line-height: 18px;
    font-style: italic;
    margin-bottom: 4px;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .timestamp i {
    margin-right: 4px;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .body {
    display: block;
    /* width: 87%; */
    padding: 1rem;
    background: #3498DB;
    border-radius: 10px;
    float: right;
    position: relative;
    text-align: left;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .body .message {
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    width: auto;
    background: #3498db;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .body .message:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent #3498db transparent transparent;
    right: 0;
    top: 10px;
    margin-right: -7px;
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .body .message a.white {
    color: white;
    font-weight: bold;
}

.chat .chat-wrapper .message-list-wrapper .message-list li.right .body .message img {
    max-width: 320px;
    max-height: 320px;
    width: 100%;
    height: auto;
    margin-bottom: 5px;
}

.chat .chat-wrapper .compose-area {
    padding: 10px 0;
    text-align: right;
}

.chat .chat-wrapper .compose-box {
    padding: 10px 0;
}

.chat .chat-wrapper .recipient-box {
    padding: 10px 0;
}

.chat .chat-wrapper .recipient-box .bootstrap-tagsinput {
    display: block;
    width: 100%;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .chat .chat-wrapper .chat-list-wrapper {
        border: 1px solid #ddd;
        height: 300px;
        overflow-y: auto;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list {
        padding: 0;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li {
        display: block;
        padding: 20px 10px;
        clear: both;
        border-bottom: 1px solid #ddd;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li .avatar {
        display: none;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li .body .header {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li .body .header .username {
        font-weight: bold;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li .body .header .timestamp {
        float: right;
        color: #999;
        font-size: 11px;
        line-height: 18px;
        font-style: italic;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li .body .header .timestamp i {
        margin-right: 4px;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li .body p {
        display: none;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li.active {
        color: black;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li.active .body .timestamp {
        color: black;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li.new {
        font-weight: bolder;
    }

    .chat .chat-wrapper .chat-list-wrapper .chat-list li.new .body .timestamp {
        font-weight: bolder;
    }

    .chat .chat-wrapper .message-list-wrapper .message-list li.left .avatar {
        display: none;
    }

    .chat .chat-wrapper .message-list-wrapper .message-list li.left .username {
        display: inline-block;
        margin-right: 10px;
    }

    .chat .chat-wrapper .message-list-wrapper .message-list li.left .body {
        width: 100%;
    }

    .chat .chat-wrapper .message-list-wrapper .message-list li.right .avatar {
        display: none;
    }

    .chat .chat-wrapper .message-list-wrapper .message-list li.right .username {
        display: inline-block;
        margin-left: 10px;
    }

    .chat .chat-wrapper .message-list-wrapper .message-list li.right .timestamp {
        text-align: right;
        display: block;
        color: #999;
        font-size: 11px;
        line-height: 18px;
        font-style: italic;
        margin-bottom: 4px;
    }

    .chat .chat-wrapper .message-list-wrapper .message-list li.right .timestamp i {
        margin-right: 4px;
    }

    .chat .chat-wrapper .message-list-wrapper .message-list li.right .body {
        width: 100%;
    }

    .chat .chat-wrapper .recipient-box {
        margin-top: 30px;
    }
}

.btn-green {
    background-color: #2ecc71;
    border-color: #27ae60;
    color: white;
}

.mg-btm-10 {
    margin-bottom: 10px !important;
}

.panel-white {
    border: 1px solid #dddddd;
}

.panel {
    border-radius: 0;
    margin-bottom: 30px;
}

.border-top-green {
    border-top: 4px solid #27ae60 !important;
}

.payrollTable tbody tr:hover {
    cursor: pointer;
}

.payslipModal::-webkit-scrollbar {
    width: 6px;
}

.payslipModal::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.payslipModal::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.payslipModal::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.notiheaderLinks::-webkit-scrollbar {
    width: 8px;
}

.notiheaderLinks::-webkit-scrollbar-thumb {
    background-color: #3498db;
    /* Color of the thumb */
    border-radius: 6px;
    /* Rounded corners of the thumb */
}

.notiheaderLinks::-webkit-scrollbar-track {
    background-color: #eee;
    /* Color of the track */
}

/* Mozilla Firefox scrollbar styling */
.notiheaderLinks {
    scrollbar-width: thin;
    scrollbar-color: #888 #eee;
}

.recruitment table thead tr th {
    font-weight: bold;
    /* border: 1px solid; */
    padding: 0 .5rem;
    white-space: nowrap;
}

.recruitment table tbody tr th,
.recruitment table tbody tr td {
    padding: 1rem 0.5rem;
    white-space: nowrap;
}

.create_job .col-4 .inp_box {
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 1rem;


}

.create_job .col-12 .inp_box {
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 1rem;


}

.create_job .col-4 .inp_box input {
    background: none;
    outline: none;
    border: none;
    padding: .5rem;
    width: 90%;
}

.create_job .col-12 .inp_box textarea {
    background: none;
    outline: none;
    border: none;
    padding: .5rem;
    width: 90%;
}

.applicant .nav .nav-link {
    margin-left: 1rem;
    border-radius: 5px;
}

.message-list .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    box-shadow: none !important;
    border: 1px solid #6cff5971 !important;
    border-radius: 5px !important;
    width: 250px !important;
}

.create_compant_timing .row .col-3 {
    border: 1px solid lightgray;
    margin-left: .2rem;
    border-radius: 5px;
    padding: 6px;
    margin-bottom: 10px;


}

.create_compant_timing .row {
    gap: 10px;
}

/* .create_compant_timing .row .col-3 label{
    display: block;
    
} */
.create_compant_timing .row .col-3 input {
    border: none;
    background: none;
    margin-left: 10px;

}

.onfocus {
    border: none;
    border: ''
}

.onfocus:focus,
.form-select:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.table {
    font-size: 13px !important;
}

.table th {
    font-weight: bold !important;
}

.xl_border {
    border: #bdbdbd38;
}

.card_shadow {
    box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04);
}


/* CSS for the full-screen overlay */
.overlayy {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.38);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    cursor: pointer;
}

/* Define the scrollbar track */
.scrollBar::-webkit-scrollbar {
    width: 6px;
    /* Adjust this to control the scrollbar's width */
}

/* Define the scrollbar thumb (the draggable part) */
.scrollBar::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the thumb */
    border-radius: 3px;
    /* Rounded corners for the thumb */
}

/* Define the scrollbar track on hover */
.scrollBar::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the track */
}

/* Define the scrollbar thumb on hover */
.scrollBar::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color of the thumb on hover */
}

/* CSS classes for different statuses */
.status-todo {
    background-color: blue;
    color: white;
    /* Optionally set the text color for better contrast */
}

.status-in-progress {
    background-color: orange;
    color: white;
}

.status-qa {
    background-color: black;
    color: white;
}

.status-done {
    background-color: green;
    color: white;
}

.projectTable th,
.projectTable td {
    padding: 5px !important;
}

:where(.css-1vr7spz).ant-menu-light .ant-menu-item-selected,
:where(.css-1vr7spz).ant-menu-light>.ant-menu .ant-menu-item-selected {
    background-color: transparent !important;
}

.main_loginPage {
    width: 80%;
    /* border: 1px solid; */
    border-radius: 10px;
    overflow: hidden;
}

.login_main {
    display: flex;
    justify-content: center;
    background: linear-gradient(to right, rgba(252, 252, 252, 0.753), #aacbff);
}

.invoce_Extra {
    padding-left: .5rem;
    padding-top: .2rem;
    /* border: 1px solid; */
}

.invoce_Extra::-webkit-scrollbar {
    display: none;
}

.leftwatermark {
    display: none !important;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: inherit !important;
}

/* ====================loding===================== */
.loding_main .sphere-container {
    align-items: center;
    display: flex;
    height: 70vh;
    justify-content: center;
}

.loding_main .sphere {
    background: radial-gradient(circle at 30% 30%, #a0e1dc, #54a0c9, #1288C8);
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    height: 15px;
    margin: 0 3px;
    width: 15px;
}

.loding_main .sphere1 {
    animation: sphere 1.2s 0.2s ease-in-out infinite;
}

.loding_main .sphere2 {
    animation: sphere 1.2s 0.5s ease-in-out infinite;
}

.loding_main .sphere3 {
    animation: sphere 1.2s 0.8s ease-in-out infinite;
}

@keyframes sphere {
    0% {
        transform: scale(1.2);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.7);
    }

    50% {
        transform: translate(0, 20px);
        box-shadow: 0 7px 10px rgba(0, 0, 0, 0.5);
    }

    100% {
        transform: scale(1.3);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0);
    }
}

.announcement {
    /* clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 71% 88%, 50% 75%, 0% 75%); */
    width: 700px;
    height: 300px;
    background: white;
}

.announcement-head {
    font-family: 'Rubik', sans-serif;

}

.card-content {
    min-height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0.5rem 0 0;
    background: #fff;
    border-radius: 3px;
    border-bottom: 1px solid #ccc;
    position: relative;
}

.card-content .card-title-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 15px;
    transition: background-color 0.1s;
}

.card-content .card-title-container p {
    padding: 0.7rem 0.5rem 0.5rem;
    max-width: 90%;
    overflow: hidden;
    word-wrap: break-word;
}

.card-content .card-title-container button {
    padding: 0.5rem 0.5rem 0 0;
    background: none;
    border: none;
    cursor: pointer;
}

.card-content .card-title-container button svg {
    opacity: 0;
    transition: color 0.2s, opacity 0.2s;
}

.card-content .card-title-container button svg:hover {
    color: #e22bba;
}

.card-content .card-title-container:hover {
    background-color: #cecece1f;
}

.card-content .card-title-container:hover svg {
    opacity: 1;
}

.card-content .input-card-title {
    width: 100%;
    height: 100%;
    padding: 0.7rem 0.5rem 0.5rem;
    resize: none;
    overflow: hidden;
    border-color: transparent;
    border-radius: 3px;
    font-size: 15px;
    transition: border-color 0.2s;
}

.card-content .input-card-title:focus {
    border: 1px solid #e22bba;
}

.input-card {
    background-color: #ebecf0;
    padding: 1rem;
    border-radius: 5px;
}

.input-card .input-card-container {
    width: 100%;
    margin: auto;
}

.input-card .input-card-container .input-text {
    width: 100%;
    height: 4rem;
    background-color: #fff;
    padding: 0.5rem;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid #ccc;
    resize: none;
}

.input-card .confirm {
    width: 268px;
    margin: 0.2rem auto;
    display: flex;
    align-items: center;
}

.input-card .confirm .button-confirm {
    height: 2rem;
    width: 6rem;
    background-color: #525252;
    cursor: pointer;
    border: none;
    border-radius: 7px;
    color: #fff;
    font-weight: bold;
    margin-right: 0.5rem;
}

.input-card .confirm .button-confirm:hover {
    background-color: #e22bba;
}

.input-card .confirm .button-cancel {
    background-color: none;
    border: none;
    cursor: pointer;
}

.input-card .confirm .button-cancel svg {
    transition: color 0.3s;
}

.input-card .confirm .button-cancel svg:hover {
    color: #e22bba;
}

.input-container {
    width: 100%;
    max-width: 300px;
    margin-right: 0.5rem;
}

.input-container .input-content {
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
    background-color: #ebecf0;
    padding: 0.5rem 0;
    opacity: 0.8;
}

.input-container .input-content button {
    cursor: pointer;
    background: none;
    width: 100%;
    padding: 0.5rem;
    border: none;
    margin: auto;
    text-align: left;
    font-size: 14px;
}

.input-container .input-content:hover {
    background-color: #ddd;
}

.list-cards {
    width: 300px;
    background-color: #ebecf0;
    margin-right: 0.5rem;
    border-radius: 5px;
}

.list-cards .title-list {
    padding: 1rem 1rem 0;
}

.list-cards .container-cards {
    overflow-x: auto;
    max-height: 55vh;
    padding: 0 1rem;
}

.list-cards .card-container {
    overflow-y: hidden;
    margin: 0.5rem 0;
}

.input-title {
    width: 100%;
    height: 1.7rem;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
}

.input-title:focus {
    background: #ddd;
}

.editable-title-container {
    position: relative;
    display: flex;
}

.editable-title-container .editable-title {
    height: 1.7rem;
    flex-grow: 1;
    font-size: 1.2rem;
    font-weight: bold;
}

.editable-title-container .list-button {
    height: 1.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.editable-title-container .list-button:hover {
    background-color: #ddd;
}

.editable-title-container .menu-card {
    position: absolute;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    z-index: 999;
    list-style-type: none;
    cursor: default;
}

.editable-title-container .menu-card li {
    color: #333;
    font-size: 0.875rem;
    border-bottom: 1px solid #ddd;
    padding: 0.5rem 0.5rem 0.3rem;
}

.editable-title-container .menu-card li:hover {
    background-color: #ddd;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 0.5rem;
    display: flex;
    min-height: calc(100vh - 5rem);
    overflow-y: auto;
}

.link_hover {
    text-decoration: underline !important;

}

.link_hover:hover {
    color: #2066ff !important;
}

#myBtn {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 600;
    position: relative;
    background-color: #ffffff;
    border: none;
    font-size: 18px;
    color: #273342;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 240px;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}

/* The Modal (background) */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 40px !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

}

.policymodal {
    --mdb-modal-width: 1300px !important;
}

.policymodal .modal-content {
    max-width: 1300px !important;
    height: 651px;
    -webkit-animation-name: animatetop1;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop1;
    animation-duration: 0.4s;
}

@-webkit-keyframes animatetop1 {
    from {
        right: -300px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

@keyframes animatetop1 {
    from {
        right: -300px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

.modal-content {
    border-radius: 10px;
    position: relative;
    background-color: #fefefe;
    margin-top: -50px;
    padding: 0;
    border: 1px solid #888;
    width: 100%;
    max-width: 544px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
}

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.close {
    color: #273342;
    float: right;
    font-size: 28px;
    font-weight: 600;
    margin: 10px;
}

.close:hover,
.close:focus {
    color: #273342;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    border-radius: 20px;
    padding: 2px 10px;
    background-color: #fff;
    color: #273342;
}

.modal-body {
    /* border:2px solid #ddd; */
    padding-left: 20px;
    height: auto;
    text-align: center;
}

.modal-footer {
    position: relative;
    border-radius: 20px;
    padding: 2px 10px;
    background-color: #fff;
    color: #273342;
    height: 80px;
    justify-content: space-between;
}

.btn-close {
    display: none;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-layout.ant-layout-has-sider {
    height: 100vh !important;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
    border: none !important;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
    border: none !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    left: 11px !important;
    top: -10px !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
    padding: 0px !important;
}

.jodit-container {
    min-height: 355px !important;
}

.myproject-drawer .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 850px !important;
    overflow-y: hidden;
    border-radius: 14px;
}

.myproject2-drawer .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 1345px !important;
    overflow-y: hidden;
    border-radius: 14px;
    overflow-x: hidden;
}

.mycmpny-drawer .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 1250px !important;
    overflow-y: hidden;
    border-radius: 10px !important;
}

.mypolicy-drawer .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 1250px !important;
    overflow-y: hidden;
    border-radius: 10px !important;
    padding: 40px;
    background: #F5F6FA;
}

.circle1 {
    position: relative;
}

.circle1::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 240px;
    left: 48%;
    top: 0;
    z-index: 1;
    background: darkgray;

}
.wizradparent1{
    height: 100vh;
}
.mydrop .dropdown-toggle:after {
    display: none;
}

.ant-drawer-content-wrapper {

    width: 90% !important;
}

.ant-drawer-body {
    overflow: hidden !important;
}

.addprojbtn button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* padding: 24px; */
    height: 50px;
    border-radius: 50%;
    width: 50px;
    border: none;
    background: #667085;
    color: wheat;
    font-size: 25px;
}

.avartarGroupBox .css-1ytufz-MuiAvatarGroup-root .MuiAvatar-root {
    height: 30px;
    width: 30px;
    font-size: 10px;
}

.companymodal .modal-body {

    padding-left: 0px !important;
    padding: 0px !important;
}

.companymodal .modal-content {
    width: 70% !important;
}

.row1col1::-webkit-scrollbar,
.row1col2::-webkit-scrollbar {
    width: 1px;
    display: none;
}

.row1col1::-webkit-scrollbar-thumb,
.row1col2::-webkit-scrollbar {
    background-color: #111C43;
    border-radius: 4px;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-drawer .ant-drawer-header {
    padding: 0px !important;
}

.anticon {
    display: none;
}

.inputsection {
    padding: 10px;
    overflow-x: hidden;
}

.profilecard .modal-content {
    width: 70% !important;
    padding: 0px !important;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-modal-root .ant-modal-wrap {
    z-index: 9999;
}

.employeecard {
    height: 140px;
    width: 225px;
}

#mysider1 #mysider2 {
    min-width: 220px !important;
}

.MuiAutocomplete-popper {
    z-index: 9999999 !important;
}

.jodit-workplace {
    min-height: 272px !important;
    height: auto;
}

.myjodit .jodit-ui-group {
    display: none !important;
}

.myjodit .jodit-container {
    border: none !important;
}

.myjodit .jodit-toolbar__box {
    border-bottom: none !important;
}

.myjodit .jodit-status-bar {
    display: none !important;
}